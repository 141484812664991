import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

import styles from "./Bank.module.css";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";
import Transaction from "./Transactions/Transaction";

const Bank = (props) => {
  const [loading, setLoading] = useState(false);
  const [fetched, setFetched] = useState({ data: null });
  const [error, setError] = useState({ status: false });

  const refreshHandler = async (event) => {
    const url = process.env.REACT_APP_API_BASE_URL + "/bank/transactions";
    setError({ status: false });
    setLoading(true);
    const token = localStorage.getItem("token");
    // event.preventDefault();
    try {
      const axiosGet = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });
      // console.log(axiosGet);
      if (axiosGet.data.bankerr)
        setError({
          status: true,
          msg: "Банкны хариу: " + axiosGet.data.bankerr.message,
        });
      if (axiosGet.data.results.length !== 0) {
        axiosGet.data.results.sort(function (a, b) {
          return a.time.localeCompare(b.time);
        });
        setFetched(axiosGet);
      }
      setLoading(false);
    } catch (err) {
      // console.log("Refresh error =", err);
      setFetched({ status: 500, ...err });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (props.isAuthenticated) {
      refreshHandler();
    }
  }, [props.isAuthenticated]);

  let errorMessage = null;
  if (error.status) {
    errorMessage = <p style={{ color: "red" }}>{error.msg}</p>;
  }

  let transactions = <div>Орлогын гүйлгээ байхгүй байна!</div>;
  if (fetched.status === 200) {
    transactions = fetched.data.results.map((el) => (
      <Transaction key={el._id} transaction={el} />
    ));
  } else if (fetched.status === 500) {
    transactions = (
      <div>
        Мэдээлэл татахад алдаа гарлаа. Хариуцсан ажилтантай холбогдоно уу!
      </div>
    );
  }

  if (loading) {
    transactions = <Spinner />;
  }

  return (
    <div className={styles.Bank}>
      ХААН банк данс: {props.khanbank !== null ? props.khanbank : null}
      <ul>{transactions}</ul>
      {errorMessage}
      <Button btnType="Send" clicked={refreshHandler}>
        Шинэчлэх
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.error,
    isAuthenticated: state.token !== "init" && state.token !== null,
    token: state.token,
    authRedirectPath: state.authRedirectPath,
    name: state.name,
    khanbank: state.bankaccounts.khanbank,
  };
};

export default connect(mapStateToProps)(Bank);

import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import axios from '../../axios-api';
import Input from '../../components/UI/Input/Input';
import Button from '../../components/UI/Button/Button';
import Spinner from '../../components/UI/Spinner/Spinner';
import withErrorHandler from '../../hoc/withErrorHandler/withErrorHandler';
import styles from './Login.module.css';
import * as actions from '../../store/actions/index';

const Login = props => {
    const [authForm, setAuthForm] = useState({
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                placeholder: 'Утасны дугаар эсвэл Имэйл хаяг'
            },
            value: '',
            validation: {
                required: true,
                isPhone: true
            },
            valid: false,
            touched: false
        },
        password: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                placeholder: 'Нууц үг'
            },
            value: '',
            validation: {
                required: false
            },
            valid: false,
            touched: false
        }
    });

    const { authRedirectPath, onSetAuthRedirectPath } = props;

    useEffect(() => {
        if (authRedirectPath !== '/revenue') {
            onSetAuthRedirectPath();
        }
    }, [authRedirectPath, onSetAuthRedirectPath])

    const inputChangedHandler = (event, controlName) => {
        const updateControls = {
            ...authForm,
            [controlName]: {
                ...authForm[controlName],
                value: event.target.value,
            }
        }
        setAuthForm(updateControls);
    }

    const submitHandler = (event) => {
        event.preventDefault();
        props.onAuth(authForm.email.value, authForm.password.value);
    }

    const formElementArray = [];
    for (let key in authForm) {
        formElementArray.push({
            id: key,
            config: authForm[key]
        });
    }

    let form = formElementArray.map(formElement => (
        <Input
            key={formElement.id}
            elementType={formElement.config.elementType}
            elementConfig={formElement.config.elementConfig}
            value={formElement.config.value}
            valid={formElement.config.valid}
            shouldValidate={formElement.config.validation}
            touched={formElement.config.touched}
            changed={(event) => inputChangedHandler(event, formElement.id)} />
    ));

    if (props.loading) {
        form = <Spinner />
    }

    let errorMessage = null;

    if (props.error) {
        errorMessage = (
            <p style={{ color: "red" }}>{props.error}</p>
        );
    }

    let authRedirect = null;
    if (props.isAuthenticated) {
        authRedirect = <Navigate to="/dashboard" replace />
    }

    return (
        <div className={styles.Auth}>
            {authRedirect}
            <form onSubmit={submitHandler}>
                {form}
                <Button btnType="Send">Нэвтрэх</Button>
            </form>
            {errorMessage}
        </div>
    )

}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        error: state.error,
        isAuthenticated: state.token !== null && state.token !== 'init',
        authRedirectPath: state.authRedirectPath
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onAuth: (email, password) => dispatch(actions.auth(email, password)),
        onSetAuthRedirectPath: () => dispatch(actions.setAuthRedirectPath('/'))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withErrorHandler(Login, axios));
import React from 'react';

import styles from './Input.module.css';

const input = (props) => {
    let inputElement = null;
    const inputStyles = props.label ? [styles.InputElement] : [styles.InputElementNoLabel];
    inputStyles.push(`${styles[props.styles]}`);

    switch (props.elementType) {
        case ('submit'):
            inputElement = <input
                type="submit"
                id={props.name}
                className={inputStyles.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
            />;
            break;
        case ('textarea'):
            inputElement = <textarea
                className={inputStyles.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed} />;
            break;
        default:
            inputElement = <input
                type={props.type}
                id={props.id}
                className={inputStyles.join(' ')}
                {...props.elementConfig}
                value={props.value}
                onChange={props.changed}
                onInput={props.oninput}
                disabled={props.disabled}
            />;
    }
    
    return (
        <div className={styles.Input}>
            {props.label ? <label className={styles.Label}>{props.label}</label> : null}
            {inputElement}
        </div>
    )
}

export default input;
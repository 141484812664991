import React from 'react';

import sparkLogo from '../../assets/images/favicon.ico';
import styles from './Logo.module.css';

const logo = (props) => {
    return (
        <div className={styles.Logo} style={{ height: props.height }}>
            <img src={sparkLogo} alt="Spark Beauty" />
        </div>
    )
};

export default logo;
import * as actionTypes from '../actions/actionTypes';

const initialState = {
    token: 'init',
    userId: null,
    name: null,
    error: null,
    loading: false,
    authRedirectPath: '/',
    bankaccounts: {
        khanbank: null,
        tdbm: null
    }
};

const authStart = (state, action) => {
    return {
        ...state,
        error: null,
        loading: true
    }        
};

const authSuccess = (state, action) => {
    return {
        ...state,
        token: action.idToken,
        userId: action.userId,
        name: action.name,
        bankaccounts: action.bankaccounts,
        error: null,
        loading: false
    };
};

const authFail = (state, action) => {
    return {
        ...state,
        error: action.error,
        loading: false
    };
};

const authLogout = (state, action) => {
    return {
        ...state, token: null, userId: null
    };
};

const setAuthRedirectPath = (state, action) => {
    return { ...state, setAuthRedirectPath: action.path };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        case actionTypes.SET_AUTH_REDIRECT_PATH: return setAuthRedirectPath(state, action);
        default:
            return state;
    }
};

export default reducer;
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';
import Layout from './hoc/Layout/Layout';
import Login from './containers/Auth/Login';
import Logout from './containers/Auth/Logout/Logout';
import Dashboard from './containers/Dashboard/Dashboard';
import Revenue from './containers/Revenue/Revenue';
import Bank from './containers/Bank/Bank';
import * as actions from './store/actions/index';

const App = props => {
  const { onTryAutoLogin } = props;

  useEffect(() => {
    onTryAutoLogin();
  }, [onTryAutoLogin])

  let routes = (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/*" element={<Login />} />
    </Routes>
  )

  if (props.isAuthenticated) {
    routes = (
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="revenue" element={<Revenue />} />
        <Route path="logout" element={<Logout />} />
        <Route path="bank" element={<Bank />} />
        <Route path="/" element={<Login />} />
      </Routes>
    )
  };

  return (
    <Layout isAuthenticated={props.isAuthenticated}>
      {routes}
    </Layout>
  );
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoLogin: () => dispatch(actions.authCheckState())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

import React from 'react';

// import styles from './Transactions.module.css';

const transaction = (props) => (
    <li>
        Цаг: <b>{props.transaction.time}</b>, 
        Дүн: <b>{props.transaction.amount}</b>,
        Утга: <div style={{ fontSize: "0.8em" }}>{props.transaction.remark}</div>
    </li>
)

export default transaction;
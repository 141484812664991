import React from 'react';
import { connect } from 'react-redux';

import styles from './NavigationItems.module.css';
import NavigationItem from './NavigationItem/NavigationItem';

export const NavigationItems = (props) => {

    return (
        <ul className={styles.NavigationItems}>
            <NavigationItem link="/">{!props.isAuthenticated ? "Нэвтрэх" : "Нүүр хуудас"}</NavigationItem>
            {props.isAuthenticated ? <NavigationItem link="/revenue">Орлого бүртгэх</NavigationItem> : null}
            {props.isAuthenticated ? <NavigationItem link="/bank">Данс шалгах</NavigationItem> : null}
            {props.isAuthenticated ? <NavigationItem link="/logout">Гарах</NavigationItem> : null}
        </ul>
    )
};

const mapStateToProps = state => {
    return {
        isAuthenticated: state.token !== null
    };
};

export default connect(mapStateToProps)(NavigationItems);
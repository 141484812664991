import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

import styles from "./Revenue.module.css";
import Input from "../../components/UI/Input/Input";
import { thousandSeparator } from "../../components/UI/Input/Formatter";
import Button from "../../components/UI/Button/Button";
import Spinner from "../../components/UI/Spinner/Spinner";

export const Revenue = (props) => {
  const today = new Date().toLocaleDateString("fr-CA", {
    timeZone: "Asia/Ulaanbaatar",
  });
  const [axiosRes, setAxiosRes] = useState({
    status: null,
    date: today,
    data: {
      sent: false,
    },
  });
  const [revenueForm, setRevenueForm] = useState({
    cash: {
      elementType: "input",
      label: "Бэлэн",
      value: "0",
      disabled: false,
    },
    card: {
      elementType: "input",
      label: "Карт",
      value: "0",
    },
    bank: {
      elementType: "input",
      label: "Данс",
      value: "0",
    },
    sale: {
      elementType: "input",
      label: "Хямдрал",
      value: "0",
    },
    expense: {
      elementType: "input",
      label: "Зардал",
      value: "0",
    },
  });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (axiosRes.status === null && props.isAuthenticated) {
      setLoading(true);
      revenueGetHandler((data) => {
        const upRevForm = { ...revenueForm };
        for (let key in data) {
          upRevForm[key].value = thousandSeparator(data[key]);
        }
        setRevenueForm(upRevForm);
      });
    }
  }, [axiosRes, revenueForm, props.isAuthenticated]);

  const revenueGetHandler = (callback) => {
    let url = process.env.REACT_APP_API_BASE_URL + "/revenue";
    const token = localStorage.getItem("token");
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setLoading(false);
        if (res.data === null) return null;
        let date = res.data.date.slice(0, 10);
        setAxiosRes({
          status: res.status,
          date: date,
          data: {
            sent: true,
            message: `${date} өдрийн мэдээ бүртгэгдсэн байна.`,
          },
        });
        callback(res.data.revTypes);
      })
      .catch((err) => {
        // console.log('error= ', err);
        setLoading(false);
      });
  };

  // input deerh uurchlult buriig RevenueForm state ruu
  // zuwhun toogoor myangani orongiin formataar oruulna
  const inputChangeHandle = (event, id) => {
    const upRevForm = { ...revenueForm };
    upRevForm[id].value = thousandSeparator(event.target.value);
    setRevenueForm(upRevForm);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const revData = {
      cash: Number(revenueForm.cash.value.replace(/,/g, "")),
      card: Number(revenueForm.card.value.replace(/,/g, "")),
      bank: Number(revenueForm.bank.value.replace(/,/g, "")),
      sale: Number(revenueForm.sale.value.replace(/,/g, "")),
      expense: Number(revenueForm.expense.value.replace(/,/g, "")),
      password: event.target.password.value,
    };
    let url = process.env.REACT_APP_API_BASE_URL + "/revenue/save";
    const token = localStorage.getItem("token");
    setLoading(true);
    axios
      .put(url, revData, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.status === 201) {
          setAxiosRes({
            status: 201,
            date: res.data.date,
            data: { sent: true, message: res.data.message },
          });
        } else setAxiosRes(null);
        setLoading(false);
      })
      .catch((err) => {
        err.response = { ...err.response, date: today };
        setLoading(false);
        setAxiosRes(err.response);
      });
  };

  let alertView = null;
  let editBtnView = null;
  let sendBtnView = loading ? null : <Button btnType="Send">Илгээх</Button>;
  if (axiosRes.status === 401) {
    alertView = <div style={{ color: "red" }}>{axiosRes.data}</div>;
  } else if (axiosRes.data.sent) {
    alertView = (
      <div style={{ color: "green", margin: "5px 0 0 0" }}>
        {axiosRes.data.message}
      </div>
    );
    sendBtnView = null;
    editBtnView = (
      <Button
        btnType="Send"
        clicked={() => {
          setAxiosRes({ status: "edit", date: today, data: { sent: false } });
        }}
      >
        Засах
      </Button>
    );
  }

  const confirmView = axiosRes.data.sent ? (
    <div>
      <h4>
        Бүртгэсэн: <span style={{ color: "pink" }}>{props.name}</span>
      </h4>
    </div>
  ) : loading ? (
    <Spinner />
  ) : (
    <div>
      <h4>
        Бүртгэсэн: <span style={{ color: "pink" }}>{props.name}</span>
      </h4>
      <div>Нууц үг оруулж баталгаажуулна!</div>
      <Input
        elementType="input"
        label="Батлах: "
        elementConfig={{
          name: "password",
          type: "password",
          placeholder: "Нууц үг",
        }}
      />
    </div>
  );

  // Convert Revenue object to Array
  const revenueElementArray = [];
  for (let key in revenueForm) {
    revenueElementArray.push({
      id: key,
      config: revenueForm[key],
    });
  }

  return (
    <div className={styles.Revenue}>
      <h2>Борлуулалтын мэдээ</h2>
      <h3>{axiosRes.date}</h3>
      <form onSubmit={onSubmit}>
        {revenueElementArray.map((el) => (
          <Input
            key={el.id}
            type={el.config.elementType}
            id={el.id}
            label={el.config.label}
            value={el.config.value}
            disabled={axiosRes && axiosRes.status === 201}
            changed={(event) => inputChangeHandle(event, el.id)}
          />
        ))}
        {confirmView}
        {alertView}
        {sendBtnView}
      </form>
      {editBtnView}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    isAuthenticated: state.token !== "init" && state.token !== null,
    authRedirectPath: state.authRedirectPath,
    name: state.name,
  };
};

export default connect(mapStateToProps)(Revenue);
